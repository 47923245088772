module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-identity/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://peaceful-turing-1274a7.netlify.app/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Varta"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
